<div class="flex mt-2 mb-2 justify-content-between">
  <span class="text-4xl">Spoke Requests</span>

  <p-button label="Create New Spoke Request" icon="pi pi-plus" styleClass="p-button-link" (click)="openSpokeRequestCreateDialog()"></p-button>
</div>

<div class="flex mt-2 mb-2 justify-content-around">
  <div class="flex-1 mr-2">
    <input pInputText class="w-full"
      [(ngModel)]="filters.requestIdContains"
      (change)="filterList()"
      placeholder="Search for Request"
      appendTo="body"
      styleClass="w-full"
      inputStyleClass="w-full">
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterProjects"
      [(ngModel)]="filters.project"
      [editable]="false"
      placeholder="Select Project..."
      [showClear]="true"
      (onChange)="filterList()"
      appendTo="body"
      styleClass="w-full"
      inputStyleClass="w-full">
    </p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterCreators"
      [(ngModel)]="filters.creator"
      [editable]="false"
      placeholder="Select Creator..."
      [showClear]="true"
      (onChange)="filterList()"
      appendTo="body"
      styleClass="w-full"
      inputStyleClass="w-full">
  </p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterStatuses"
      [(ngModel)]="filters.status"
      [editable]="false"
      placeholder="Select Status..."
      [showClear]="true"
      (onChange)="filterList()"
      appendTo="body"
      styleClass="w-full"
      inputStyleClass="w-full">
  </p-dropdown>
</div>

<div class="">
  <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear();"></i>
</div>
</div>

<p-table
  [value]="spokeRequestsFiltered"
  styleClass="p-datatable-sm w-full"
  [rowHover]="true"
  sortField="spokeId"
  [sortOrder]="-1"
>
<ng-template pTemplate="header">
  <tr>
    <th pSortableColumn="mappingId">Request Id</th>
    <th pSortableColumn="spokeId">Spoke Id</th>
    <th pSortableColumn="projectId">Project Id</th>
    <th pSortableColumn="projectName">Project Name</th>
    <th pSortableColumn="subscriptionId">Subscription Id</th>
    <th pSortableColumn="environment">Environment</th>
    <th pSortableColumn="status">Status</th>
    <th pSortableColumn="deploymentId">Deployment Run Id</th>
    <th pSortableColumn="createdBy">Created By</th>
    <th></th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-item let-i="rowIndex">
  <tr (dblclick)="openSpokeRequestDetailsDialog(item.mappingId)" class="cursor-pointer">
    <td>{{item.mappingId ?? 'n/a' }}</td>
    <td>{{item.spokeId ?? 'n/a' }}</td>
    <td>{{item.projectId ?? 'n/a' }}</td>
    <td>{{item.projectName ?? 'n/a' }}</td>
    <td>{{item.subscriptionId ?? 'n/a' }}</td>
    <td>{{item.environment ?? 'n/a' }}</td>
    <td>{{item.status ?? 'n/a' }}</td>
    <td class="cursor-pointer">
      @if (item.deploymentId){
      <a href="{{item.deploymentUrl}}" class="underline" target="_blank">
        <i class="pi pi-external-link pr-2 text-xs"></i>
        <span>{{item.deploymentId}}</span>
      </a>
      <i class="pi pi-globe text-sm pl-1 {{getStatusClass(item.globalDnsStatus)}}" title="GlobalDNS:{{item.globalDnsStatus}}" (click)="globalDnsDialog(item)"></i>
      }
    </td>
    <td>{{item.createdBy}}</td>
    <td class="text-right">
      <p-button *ngIf="item.status === 'New'"
                icon="pi pi-check-square"
                styleClass="p-button-link"
                (click)="redirectToApproval(item.mappingId)"
                pTooltip="Approve/Reject"
                tooltipPosition="left">
      </p-button>
      <p-button icon="pi pi-info-circle"
                styleClass="p-button-link"
                (click)="openSpokeRequestDetailsDialog(item.mappingId)"
                pTooltip="Show Details"
                tooltipPosition="left">
      </p-button>

    </td>
  </tr>
</ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>
