<div class="flex mt-6 justify-content-center">
  <p-card header="Spoke Request - Approval" [subheader]="spokeRequest?.mappingId" class="w-5">
    <ng-template pTemplate="header">
      <div class="block text-white">
        <i class="bg-primary text-lg pi pi-chevron-left" style="padding:5px"></i>
        <a style="cursor: pointer" (click)="onReturnBackToSpokeRequestsClick()">&nbsp; Return back to Spoke Requests</a>

      </div>
    </ng-template>

    <h5>Spoke Description</h5>

    @if (spokeRequestForm || (spokeRequestForm && !formBlocked)) {
      <form [formGroup]="spokeRequestForm" class="mt-4">
        <div>
          <div class="field">
            <label for="spokeId" class="block text-900 font-medium mb-2">Spoke Id</label>
            <input id="spokeId" [readOnly]="true" formControlName="spokeId" type="text" pInputText placeholder="ERROR" class="w-full disabled" />
            <div *ngIf="spokeRequestForm.controls['spokeId'].dirty && !spokeRequestForm.controls['spokeId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeRequestForm, 'spokeId')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="projectId" class="block text-900 font-medium mb-2">Project Id</label>
            <p-dropdown id="projectId" formControlName="projectId" [options]="(projectOptions | async) ?? []" placeholder="Select a Project Profile" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
            <div *ngIf="spokeRequestForm.controls['projectId'].dirty && !spokeRequestForm.controls['projectId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeRequestForm, 'projectId')">{{ error }}</small>
            </div>
          </div>
          <div *ngIf="spokeRejection" class="field">
            <label for="rejectionReason" class="block text-900 font-medium mb-2">Rejection reason</label>
            <input id="rejectionReason" formControlName="rejectionReason" type="text" pInputText placeholder="enter rejection reason" class="w-full mb-3">
            <br />
            <p-button label="Reject" (click)="onSpokeRejectClick()" [outlined]="true" class="block w-full mb-4" styleClass="w-full "/>
          </div>
        </div>
      </form>
    }
    @else {
      <div class="flex justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    }

    @if (!spokeRejection) {
      <h5>Secondary Hub Reference</h5>

      @if (secondaryHubReferenceForm || (secondaryHubReferenceForm && !formBlocked)) {
        <form [formGroup]="secondaryHubReferenceForm" class="mt-4">
          <div>
            <div class="field">
              <label for="categoryId" class="block text-900 font-medium mb-2">Secondary Hub Category Id</label>
              <p-dropdown
                id="categoryId"
                formControlName="categoryId"
                [options]="(secondaryHubCategoriesOptions | async) ?? []"
                optionLabel="name" optionValue="value"
                (onChange)="onSecondaryHubCategoryValueChange($event)"
                placeholder="Select SH Category Id"
                appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
            </div>
            <div class="field">
              <label for="subcategoryId" class="block text-900 font-medium mb-2">Secondary Hub Subcategory Id</label>
              <p-dropdown
                id="subcategoryId"
                formControlName="subcategoryId"
                [options]="secondaryHubSubcategoriesOptions ?? []"
                optionLabel="name"
                optionValue="value"
                placeholder="Select SH Category Id first then select Subcategory from this list"
                appendTo="body" styleClass="w-full" inputStyleClass="w-full" />
            </div>
          </div>
        </form>
      }
      @else {
        <div class="flex justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      }

      <h5>Spoke Data</h5>

      @if (spokeDataForm || (spokeDataForm && !formBlocked)) {
        <form [formGroup]="spokeDataForm" class="mt-4">
          <div>
            <div class="field">
              <label for="virtualNetworkCIDR" class="block text-900 font-medium mb-2">Azure Virtual Network CIDR</label>
              <input id="virtualNetworkCIDR" formControlName="virtualNetworkCIDR" type="text" pInputText placeholder="0.0.0.0/24" class="w-full" />
              <div *ngIf="spokeDataForm.controls['virtualNetworkCIDR'].dirty && !spokeDataForm.controls['virtualNetworkCIDR'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'virtualNetworkCIDR')">{{ error }}</small>
              </div>
            </div>
            <div class="field">
              <label for="subscriptionId" class="block text-900 font-medium mb-2">Azure Subscription Id</label>
              <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="enter hub name" class="w-full mb-3">
              <div *ngIf="spokeDataForm.controls['subscriptionId'].dirty && !spokeDataForm.controls['subscriptionId'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'subscriptionId')">{{ error }}</small>
              </div>
            </div>
            <div class="field">
              <label for="namingConvention" class="block text-900 font-medium mb-2">
                Spoke Name (optional)
                <i class="pi pi-question-circle"
                  pTooltip="This field is used for naming the Spoke's Resource Group. It's optional but recommended for easier identification of the Spoke's scope. When choosing your custom name, only alpha-characters can be used, (no numbers, dashes, underscores, or any other illegal characters)">
                </i>
              </label>
              <input id="namingConvention" formControlName="namingConvention" type="text" pInputText placeholder=""  (change)="onNamingConventionChange()" class="w-full">
              <div *ngIf="spokeDataForm.controls['namingConvention'].dirty && !spokeDataForm.controls['namingConvention'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'namingConvention')">{{ error }}</small>
              </div>
            </div>
            <div class="field">
              <label for="topology" class="block text-900 font-medium mb-2">Do you require connectivity to the VW corporate backbone (On-Premise)?</label>
              <p-dropdown  id="topology" formControlName="topology" [options]="spokeTopologyOptions" optionLabel="label" optionValue="value" placeholder="Please choose the answer" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
              <div *ngIf="spokeDataForm.controls['topology'].dirty && !spokeDataForm.controls['topology'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'topology')">{{ error }}</small>
              </div>
            </div>
            <div class="field">
              <label for="location" class="block text-900 font-medium mb-2">Azure Deployment Location</label>
              <p-dropdown id="location" formControlName="location" [options]="(azureRegionOptions | async) ?? []"placeholder="Select target Region" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
              <div *ngIf="spokeDataForm.controls['location'].dirty && !spokeDataForm.controls['location'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'location')">{{ error }}</small>
              </div>
            </div>
            <div class="field">
              <label for="environment" class="block text-900 font-medium mb-2">Environment</label>
              <p-dropdown id="environment" formControlName="environment" [options]="(deploymentStageOptions | async) ?? []" placeholder="Select target Environment" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
              <div *ngIf="spokeDataForm.controls['environment'].dirty && !spokeDataForm.controls['environment'].valid" class="block font-sm mb-3 text-red-500">
                <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'environment')">{{ error }}</small>
              </div>
            </div>
          </div>
        </form>
      }
      @else {
        <div class="flex justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      }
    }

    @if (!formBlocked) {
      <div *ngIf="!spokeRejection" class="flex gap-3 pt-5">
        <p-button label="Reject" (click)="onRejectClick()" [outlined]="true" class="block w-full mb-4" styleClass="w-full "/>
        <!--&& secondaryHubReferenceForm?.valid && spokeDataForm?.valid && spokeRequestForm?.valid-->
        <p-button label="Approve" (click)="onApproveClick()" [outlined]="true" [disabled]="!(spokeRequestForm?.valid) || !(spokeDataForm?.valid)" class="block w-full mb-4" styleClass="w-full"/>
      </div>
    }
    @else {
      <div class="flex-row mt-5">
        <p class="flex-1">Processing...</p>
        <p-progressBar class="flex-1" mode="indeterminate" [style]="{ height: '6px' }" inputStyleClass="w-full" />
      </div>
    }

    <ng-template pTemplate="footer"></ng-template>
  </p-card>
</div>
