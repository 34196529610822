@if (formReady) {
  <ul>
    <li><span class="font-bold">Request Id</span>: {{spokeRequest?.mappingId ?? 'n/a' }}</li>
    <li><span class="font-bold">Spoke Id</span>: {{spokeRequest?.spokeId ?? 'n/a' }}</li>
    <li><span class="font-bold">Project Id</span>: {{spokeRequest?.projectId ?? 'n/a' }}</li>
    <li><span class="font-bold">Project Name</span>: {{spokeRequest?.projectName ?? 'n/a' }}</li>
  </ul>

  <h5>Secondary Hub Reference</h5>

  <ul>
    <li><span class="font-bold">Secondary Hub Category Id</span>: {{spokeRequest?.categoryId ?? 'n/a' }}</li>
    <li><span class="font-bold">Secondary Hub Category Description</span>: {{spokeRequest?.categoryDescription ?? 'n/a' }}</li>
    <li><span class="font-bold">Secondary Hub Subcategory Id</span>: {{spokeRequest?.subcategoryId ?? 'n/a' }}</li>
    <li><span class="font-bold">Secondary Hub Subcategory Description</span>: {{spokeRequest?.subcategoryDescription ?? 'n/a' }}</li>
  </ul>

  <h5>Spoke Request Details</h5>

  <ul>
    <li><span class="font-bold">Azure Subscription Id</span>: {{spokeRequest?.subscriptionId ?? 'n/a' }}</li>
    <li><span class="font-bold">Request Status</span>: {{spokeRequest?.status ?? 'n/a' }}</li>
    <li><span class="font-bold">Approval Status</span>: {{spokeRequest?.approvalState ?? 'n/a' }}</li>
    <li><span class="font-bold">Request Created By</span>: {{spokeRequest?.createdBy ?? 'n/a' }}</li>
    <li *ngIf="spokeRequest?.approvalState === 'Approved'"><span class="font-bold">Request Approved By</span>: {{spokeRequest?.approvedBy ?? 'n/a' }}</li>
    <li *ngIf="spokeRequest?.approvalState === 'Rejected'"><span class="font-bold">Request Rejected By</span>: {{spokeRequest?.approvedBy ?? 'n/a' }}</li>
    <li *ngIf="spokeRequest?.approvalState === 'Rejected'"><span class="font-bold">Rejection Reason</span>: {{spokeRequest?.rejectionReason ?? 'n/a' }}</li>
    <li><span class="font-bold">GlobalDNS Status</span>: {{spokeRequest?.globalDnsStatus ?? 'n/a' }}</li>
    <li><span class="font-bold">GlobalDNS Error</span>: <small class="text-red-700">{{spokeRequest?.globalDnsError ?? ''}}</small></li>
  </ul>

  <h5>Excerpt from Spoke Data</h5>

  <ul>
    <li><span class="font-bold">Azure TenantId</span>: {{spokeRequest?.tenantId ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Tenant Name</span>: {{spokeRequest?.tenantName ?? 'n/a' }}</li>
    <li><span class="font-bold">Naming Convention</span>: {{spokeRequest?.namingConvention ?? 'n/a' }}</li>
    <li><span class="font-bold">Topology</span>: {{spokeRequest?.topology ?? 'n/a' }}</li>
    <li><span class="font-bold">Location</span>: {{spokeRequest?.location ?? 'n/a' }}</li>
    <li><span class="font-bold">Resource Group</span>: {{spokeRequest?.resourceGroupName ?? 'n/a' }}</li>
    <li><span class="font-bold">Virtual Network</span>: {{spokeRequest?.virtualNetworkName ?? 'n/a' }}</li>
    <li><span class="font-bold">Deployment Environment</span>: {{spokeRequest?.environment ?? 'n/a' }}</li>
  </ul>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

