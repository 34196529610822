import { Injectable } from "@angular/core";
import { BaseApiService } from "../../../../../shared/services/_base-api.service";
import { NpsStore } from "../../../../services/nps.store";
import { Observable } from "rxjs";
import { PrimaryHubDto } from "../primary-hub.model";
import { PrimaryHubResponseDto } from "../responses/primary-hub-response.model";
import { ValidationRule } from "../../../../../shared/models/validation-rule";
import { NpsConfigurationApiUrl } from "../../../shared/api/configuration.service";
import { NpsConfigService } from "../../../../services/nps-configuration.service";

export const NpsPrimaryHubUrl = {
    OpPrimaryHubsGetList: () => "/api/operations/primaryhubs/",
    OpPrimaryHubCreate: () => "/api/operations/primaryhubs/",
}

@Injectable({providedIn: 'root'})
export class PrimaryHubService extends BaseApiService {

  constructor(configuration: NpsConfigService, store: NpsStore) {
    super(configuration, store);
  }

  getValidators(method: keyof typeof NpsPrimaryHubUrl, part: string = '') {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method, part });
  }

  primaryHubsGet() : Observable<PrimaryHubResponseDto[]> {
    return super.get<PrimaryHubResponseDto[]>(NpsPrimaryHubUrl.OpPrimaryHubsGetList());
  }

  primaryHubCreate(body: PrimaryHubDto) : Observable<PrimaryHubDto> {
    return super.post(NpsPrimaryHubUrl.OpPrimaryHubCreate(), body);
  }
}
